@font-face {
  font-family: "GT America";
  src: url("./fonts/GT-America-Extended-Medium.woff2") format("woff2"),
    url("./fonts/GT-America-Extended-Medium.woff") format("woff");
}

body {
  background-color: var(--colors-black);
  color: var(--colors-white);
  font-family: var(--fonts-america);
  font-weight: var(--fontWeights-regular);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fill-available {
  height: 100vh;
  height: -moz-available;
  height: -webkit-fill-available;
}
